import React from "react"
import { Box, Container } from "@theme-ui/components"
import ArticleThumb from "../components/articleThumb"

const ArticlesList = ({ articles, pageIndex }) => {
  return (
    <Box>
      {articles.map((article, index) =>
        (pageIndex === 1 && index === 0) ? (
          <Box sx={{ mt: [-5,-9], position: "relative", display: "block" }}>
            <Container variant="lg" sx={{pb:[6,6,7,7]}}>
              <ArticleThumb
                article={article}
                key={article.id}
                index={index}
              />
            </Container>
          </Box>
        ) : (
          <Box
            sx={{
              ":hover": {
                backgroundColor: "Grey",
              },
            }}
          >
            <Container
              sx={{
                py:[7,7,7,7],
                borderTop: index=== 1 && "1px solid",
                borderBottom: "1px solid",
                borderColor: "Grey",
              }}
            >
              <ArticleThumb article={article} key={article.id} />
            </Container>
          </Box>
        )
      )}
    </Box>
  )
}

export default ArticlesList
