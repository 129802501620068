import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getArticlePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink, OutboundLink } from "./link"
import { render } from "datocms-structured-text-to-plain-text"
import PlayButtonRed from "../assets/images/play-button-red.svg"

const ArticleThumb = ({ article, small, index = null }) => {
  const [showIcon, setShowIcon] = useState()

  return (
    <Box
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      sx={{
        borderRadius: "sm",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <InboundLink
        to={getArticlePath(article, article.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Grid
          columns={[
            1,
            small ? 1 : index === 0 ? ".4fr .6fr" : ".3fr  .2fr  .55fr",
          ]}
          gap={[0,index === 0 ? 0 : 6]}
          sx={{
            minHeight: index === 0 ? "450px" : "280px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "relative",
              ".gatsby-image-wrapper": {
                height: "100%",
                
              },
            }}
          >
            <GatsbyImage image={article.heroImage.gatsbyImageData} alt={article.heroImage.gatsbyImageData} />
            {showIcon && index != 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "-30px",
                  transform: "translateY(-50%)",
                }}
              >
                <PlayButtonRed />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              position: index != 0 ? "relative" : "absolute",
              p: [3,index === 0 ? 5 : 0],
              height: "100%",
              display: index === 0 ? "flex" : "block",
              display: index === 0 ? "flex" : "block",
              flexDirection: index === 0 ? "column" : "unset",
              justifyContent: ["block",index === 0 ? "space-between" : "block"],
            }}
          >
            <Box>
              <Text
                as="p"
                sx={{
                  fontSize: [9],
                  color: index === 0 ? "light" : "dark",
                  fontWeight: "500",
                  textAlign: "left",
                }}
              >
                {article.meta.firstPublishedAt.split(/(\s+)/)[0]}
              </Text>
            </Box>
            <Box
              sx={{
                textTransform: "capitalize",
                textAlign: "left",
                color: index === 0 ? "light" : "dark"
              }}
            >
              <Box as="p">
                {
                  article.meta.firstPublishedAt
                    .split(/(\s+)/)
                    .filter(e => e.trim().length > 0)[1]
                }
              </Box>
              <Box as="p">
                {
                  article.meta.firstPublishedAt
                    .split(/(\s+)/)
                    .filter(e => e.trim().length > 0)[2]
                }
              </Box>
            </Box>
          </Box>
          <Flex
            sx={{
              p: [3,4],
              py: [4,6],
              pt: [index === 0 ? 6 : 0],
              pb: [index === 0 ? 6 : 0],
              backgroundColor: index === 0 ? "primary" : "none",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Heading
              as="h2"
              variant="h4"
              sx={{ mb: 3, mt: 0, color: index === 0 ? "light" : "dark" }}
            >
              {article.title}
            </Heading>
            <Text
              dangerouslySetInnerHTML={{ __html: article.subtitle }}
              as="div"
              variant="p"
              color={index !== 0 ? "dark" : "light"}
              sx={{
                p: { m: 0 },
              }}
            />
            <Text
              as="p"
              sx={{ fontSize: 1, color: index !== 0 ? "dark" : "light" }}
            >
              {render(article.body)
                ? render(article.body).substring(0, 120) + " ..."
                : ""}
            </Text>
          </Flex>
        </Grid>
      </InboundLink>
    </Box>
  )
}

export default ArticleThumb
